import { createSlice } from '@reduxjs/toolkit'

const ipoLiveDataSlice = createSlice({
  name: 'ipoLiveDataSlice',
  initialState: {
    liveData: [],
    liveipotype: sessionStorage.getItem("liveIpoType") ? JSON.parse(sessionStorage.getItem("liveIpoType")) : 'All IPOs',
    liveloading: true,
    liveerror: null,
    typeFilter: '',
    livenext: '',
    liveprevious: '',
    livePaginationLink: '',
    livePageSize: 1,
    livePageNo: sessionStorage.getItem("livePageNo") ? +JSON.parse(sessionStorage.getItem("livePageNo")) : 1,
  },
  reducers: {
    setliveIpoData: (state, action) => {
      state.liveData = action.payload // Fix: Updated the correct key
    },
    setLiveIpoType: (state, action) => {
      sessionStorage.setItem("liveIpoType", JSON.stringify(action.payload))
      state.liveipotype = action.payload
    },
    setTypeFilter: (state, action) => {
      state.typeFilter = action.payload
    },
    startLiveLoading: state => {
      state.liveloading = true
    },
    stopLiveLoading: state => {
      state.liveloading = false
    },
    setLiveError: (state, action) => {
      state.liveerror = action.payload
    },
    setlivenext: (state, action) => {
      state.livenext = action.payload
    },
    setLiveprevious: (state, action) => {
      state.liveprevious = action.payload
    },
    setLivePaginationLink: (state, action) => {
      state.livePaginationLink = action.payload
    },
    setLivePageSize: (state, action) => {
      state.livePageSize = action.payload
    },
    setLivePageNo: (state, action) => {
      state.livePageNo = action.payload
      sessionStorage.setItem("livePageNo", JSON.stringify(action.payload))
    }
  }
})

export const {
  setliveIpoData,
  setLiveIpoType,
  setTypeFilter,
  startLiveLoading,
  stopLiveLoading,
  setLiveError,
  clearLiveError,
  setlivenext,
  setLivePaginationLink,
  setLiveprevious,
  setLivePageSize,
  setLivePageNo
} = ipoLiveDataSlice.actions

export default ipoLiveDataSlice.reducer
