import { axiosInstance, axios } from '../utils/npm'
import { decrypt } from '../utils/utils'

export const getIpoList = async data => {
  try {
    const response = await axiosInstance.post(
      `/ipo/ipo_list?ipo_type=${data.ipo_type}&category=${data.category}`
    )
    let res = decrypt(response)
    res = JSON.parse(res)
    return res
  } catch (error) {
    console.error('Failed to fetch IPO list:', error)
    return error
  }
}

export const getIpoDetail = async id => {
  try {
    const response = await axiosInstance.get(`/ipo/${id}/`)
    let res = decrypt(response)
    res = JSON.parse(res)
    return res
    // return response
  } catch (error) {
    console.error('Failed to fetch IPO list:', error)
    return error
  }
}

export const getNewsList = async id => {
  try {
    const response = await axiosInstance.get(`/news/`)
    return response
  } catch (error) {
    console.error('Failed to fetch IPO list:', error)
    return error
  }
}

export const getNewspageWise = async url => {
  try {
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    console.error('Failed to fetch IPO list:', error)
    return error
  }
}

export const getNewspage = async (page, page_size) => {
  try {
    const response = await axiosInstance.get(
      `/news/?page=${page}&page_size=${page_size}`
    )
    return response
  } catch (error) {
    console.error('Failed to fetch IPO list:', error)
    return error
  }
}

export const getnewsDetails = async id => {
  try {
    const response = await axiosInstance.get(`/news/${id}/`)
    return response
  } catch (error) {
    console.error('Failed to fetch IPO list:', error)
    return error
  }
}

export const getIpoLiveDataPage = async (
  page,
  page_size,
  ipo_type,
  category
) => {
  let ipo_types = ipo_type
  if (ipo_types === '' || ipo_types?.toLowerCase() === 'all ipos') {
    try {
      const response = await axiosInstance.post(
        `/ipo/ipo_list?category=${category}&page=${page}&page_size=${page_size}`
      )
      let res = decrypt(response)
      res = JSON.parse(res)
      return res
    } catch (error) {
      console.error('Failed to fetch IPO list:', error)
      return error
    }
  } else {
    try {
      const response = await axiosInstance.post(
        `/ipo/ipo_list?ipo_type=${ipo_type}&category=${category}&page=${page}&page_size=${page_size}`
      )
      let res = decrypt(response)
      res = JSON.parse(res)
      return res
    } catch (error) {
      console.error('Failed to fetch IPO list:', error)
      return error
    }
  }
}

export const getIpoLiveDataWise = async url => {
  try {
    const response = await axiosInstance.post(url)
    let res = decrypt(response)
    res = JSON.parse(res)
    return res
  } catch (error) {
    return error
  }
}

export const addContactUs = async data => {
  try {
    const response = await axiosInstance.post('/contactus/', data)
    return response
  } catch (error) {
    return error
  }
}

export const faqListApi = async () => {
  try {
    const response = await axiosInstance.get(`/settings/create-list-faq/`)
    return response
  } catch (error) {
    return error
  }
}
