import { createBrowserRouter, Navigate } from 'react-router-dom'
import { PublicRoute, ProtectedRoute } from './PrivatePublicRoute'
import {
  ContactUs,
  ForgotPassword,
  HomePage,
  Login,
  SignUp,
  VerifyCode,
  SetPassWord,
  Profilepage,
  BrokerPage,
  NewsPage,
  NewsDetail,
  AboutUs,
  Components,
  CurrentIpoPage,
  UpcomingIpoPage,
  ListedIpoPage,
  BrokerDetail,
  TermsAndConditionsPage,
  PrivacyPolicyPage,
  FAQsPage
} from '../utils/npm'

const {  IpoDetail, Header, Footer } = Components

const Layout = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
)

const wrapRoute = (route, wrapper) => {
  if (wrapper === 'public') {
    return {
      ...route,
      element: <PublicRoute>{route.element}</PublicRoute>,
    };
  } else if (wrapper === 'protected') {
    return {
      ...route,
      element: <ProtectedRoute>{route.element}</ProtectedRoute>,
    };
  }
  return {
    ...route,
    element: <Layout>{route.element}</Layout>,
  };
};

// Define Routes
const routes = [
  // Public Routes
  { path: '/login', element: <Login />, wrapper: 'public' },
  { path: '/signup', element: <SignUp />, wrapper: 'public' },

  // Protected Routes
  { path: '/profile', element: <Profilepage />, wrapper: 'protected' },

  // General Routes (No Wrapper)
  { path: '/', element: <HomePage /> },
  { path: '/ipo/:id', element: <IpoDetail />},
  { path: '/contactUs', element: <ContactUs /> },
  { path: '/forgotPassword', element: <ForgotPassword /> },
  { path: '/verifyCode', element: <VerifyCode /> },
  { path: '/setNewPassword', element: <SetPassWord /> },
  { path: '/broker', element: <BrokerPage /> },
  { path: '/news', element: <NewsPage /> },
  { path: '/about-us', element: <AboutUs /> },
  { path: '/live', element: <CurrentIpoPage /> },
  { path: '/upcoming', element: <UpcomingIpoPage /> },
  { path: '/listed', element: <ListedIpoPage /> },
  { path: '/termsAndConditions', element: <TermsAndConditionsPage /> },
  { path: '/privacyPolicy', element: <PrivacyPolicyPage /> },
  { path: '/marketNews/:id', element: <NewsDetail /> },
  { path: '/broker/:id', element: <BrokerDetail /> },
  { path: '/faqs', element: <FAQsPage /> },
  // Catch-All Route
  { path: '/*', element: <Navigate to="/" /> },
];

// Wrap Routes Dynamically
const wrappedRoutes = routes.map((route) =>
  wrapRoute(route, route.wrapper)
);

// Create Router
export const routerLinks = createBrowserRouter(wrappedRoutes, {
  future: {
    v7_fetcherPersist: true,
    v7_startTransition: true,
    v7_relativeSplatPath: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
  },
});