import React from 'react'
import { Navigate } from 'react-router-dom'
import {useSelector, Components} from "../utils/npm"
const {Header, Footer} = Components

const Layout = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
)

const ProtectedRoute = ({ children }) => {
  const loginData = useSelector(state => state.loginProfile.loginData)
  return loginData && Object.keys(loginData).length > 0 ? (
    <Layout> {children}</Layout>
  ) : (
    <Navigate to='/' />
  )
}

const PublicRoute = ({ children }) => {
  const loginData = useSelector(state => state.loginProfile.loginData)
  return Object.keys(loginData).length > 0 ? (
    <Navigate to='/' />
  ) : (
    <Layout>{children}</Layout>
  )
}

export { ProtectedRoute, PublicRoute }
