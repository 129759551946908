import { createSlice } from '../../utils/npm'

const faqDataSlice = createSlice({
  name: 'faqDataSlice',
  initialState: {
    faqData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setFaqData: (state, action) => {
      state.faqData = action.payload
    },
    startFaqLoading: state => {
      state.loading = true
    },
    stopFaqLoading: state => {
      state.loading = false
    },
    setFaqError: (state, action) => {
      state.error = action.payload 
    },
    clearFaqError: state => {
      state.error = null 
    }
  }
})

export const {
  setFaqData,
  startFaqLoading,
  stopFaqLoading,
  setFaqError,
  clearFaqError,
} = faqDataSlice.actions

export default faqDataSlice.reducer
