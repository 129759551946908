// import { createSlice } from '../../utils/npm'

// const newsPageSlice = createSlice({
//   name: 'newsPageSlice',
//   initialState: {
//     newsPageData: [],
//     newsPageLoading: false,
//     newsPageError: null,
//     currentPage: 1,
//     pageSize: 1,
//     previousUrl: '',
//     nextUrl: '',
//     newsPaginationLink: "",
//   },
//   reducers: {
//     setNewsPageData: (state, action) => {
//       state.newsPageData = action.payload
//     },
//     NewsStartPageLoading: state => {
//       state.newsPageLoading = true
//     },
//     NewsStopPageLoading: state => {
//       state.newsPageLoading = false
//     },
//     setNewsPageError: (state, action) => {
//       state.newsPageError = action.payload
//     },

//     setPage: (state, action) => {
//       state.currentPage = action.payload
//     },
//     setPreviousUrl: (state, action) => {
//       state.previousUrl = action.payload
//     },
//     setNextUrl: (state, action) => {
//       state.nextUrl = action.payload
//     }
//   }
// })

// export const {
//   setNewsPageData,
//   NewsStartPageLoading,
//   NewsStopPageLoading,
//   setNewsPageError,
//   setPreviousUrl,
//   setNextUrl,
//   setPage
// } = newsPageSlice.actions

// export default newsPageSlice.reducer

import { createSlice } from '@reduxjs/toolkit'

const newsPageSlice = createSlice({
  name: 'newsPageSlice',
  initialState: {
    newsPageData: [],
    newsPageLoading: false,
    newsPageError: null,
    currentPage: 1,
    pageSize: 12,
    totalPage: 0,
    nextUrl: '',
    previousUrl: '',
    newsPaginationLink: '',
    newsPageSize: 1,
    newsPageNo: sessionStorage.getItem('newsPageNo')
      ? +JSON.parse(sessionStorage.getItem('newsPageNo'))
      : 1
  },
  reducers: {
    setNewsPageData: (state, action) => {
        state.newsPageData = action.payload
    },
    NewsStartPageLoading: state => {
      state.newsPageLoading = true
    },
    NewsStopPageLoading: state => {
      state.newsPageLoading = false
    },
    setNewsPageError: (state, action) => {
      state.newsPageError = action.payload
    },
    setPage: (state, action) => {
      state.currentPage = action.payload
    },
    setPreviousUrl: (state, action) => {
      state.previousUrl = action.payload
    },
    setNextUrl: (state, action) => {
      state.previousUrl = action.payload
    },
    setNewsPaginationLink: (state, action) => {
      state.newsPaginationLink = action.payload
    },
    setNewsPageSize: (state, action) => {
      state.newsPageSize = action.payload
    },
    setNewsPageNo: (state, action) => {
      state.newsPageNo = action.payload
      sessionStorage.setItem('newsPageNo', JSON.stringify(action.payload))
    }
  }
})

export const {
  setNewsPageData,
  NewsStartPageLoading,
  NewsStopPageLoading,
  setPreviousUrl,
  setNextUrl,
  setNewsPageError,
  setPage,
  setNewsPaginationLink,
  setNewsPageSize,
  setNewsPageNo
} = newsPageSlice.actions

export default newsPageSlice.reducer
