import { createSlice } from '@reduxjs/toolkit'

const ipoUpcomingDataSlice = createSlice({
  name: 'ipoUpcomingDataSlice',
  initialState: {
    UpcomingData: [],
    Upcomingipotype: sessionStorage.getItem("upcomingIpoType") ? JSON.parse(sessionStorage.getItem("upcomingIpoType")) : 'All IPOs',
    Upcomingloading: true,
    Upcomingerror: null,
    typeUpcomingFilter: '',
    Upcomingnext: '',
    Upcomingprevious: '',
    upcomingPaginationLink: '',
    upcomingPageSize: 1,
    upcomingPageNo: sessionStorage.getItem('upcomingPageNo')
      ? +JSON.parse(sessionStorage.getItem('upcomingPageNo'))
      : 1
  },
  reducers: {
    setUpcomingIpoData: (state, action) => {
      state.UpcomingData = action.payload
    },
    setUpcomingIpoType: (state, action) => {
      sessionStorage.setItem("upcomingIpoType", JSON.stringify(action.payload))
      state.Upcomingipotype = action.payload
    },
    setTypeUpcomingFilter: (state, action) => {
      state.typeFilter = action.payload
    },
    startUpcomingLoading: state => {
      state.Upcomingloading = true
    },
    stopUpcomingLoading: state => {
      state.Upcomingloading = false
    },
    setUpcomingError: (state, action) => {
      state.Upcomingerror = action.payload
    },
    setUpcomingnext: (state, action) => {
      state.Upcomingnext = action.payload
    },
    setUpcomingprevious: (state, action) => {
      state.Upcomingprevious = action.payload
    },
    setUpcomingPaginationLink: (state, action) => {
      state.upcomingPaginationLink = action.payload
    },
    setUpcomingPageSize: (state, action) => {
      state.upcomingPageSize = action.payload
    },
    setUpcomingPageNo: (state, action) => {
      state.upcomingPageNo = action.payload
      sessionStorage.setItem('upcomingPageNo', JSON.stringify(action.payload))
    }
  }
})

export const {
  setUpcomingIpoData,
  setUpcomingIpoType,
  setTypeUpcomingFilter,
  startUpcomingLoading,
  stopUpcomingLoading,
  setUpcomingError,
  clearUpcomingError,
  setUpcomingnext,
  setUpcomingprevious,
  setUpcomingPaginationLink,
  setUpcomingPageSize,
  setUpcomingPageNo
} = ipoUpcomingDataSlice.actions

export default ipoUpcomingDataSlice.reducer
