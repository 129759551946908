import { createSlice } from '@reduxjs/toolkit'

const ipoListedDataSlice = createSlice({
  name: 'ipoListedDataSlice',
  initialState: {
    ListedData: [],
    Listedipotype: sessionStorage.getItem("listedIpoType") ? JSON.parse(sessionStorage.getItem("listedIpoType")) : 'All IPOs',
    Listedloading: true,
    Listederror: null,
    typeListedFilter: '',
    Listednext: '',
    Listedprevious: '',
    listedPaginationLink: '',
    listedPageSize: 1,
    listedPageNo: sessionStorage.getItem('listedPageNo')
      ? +JSON.parse(sessionStorage.getItem('listedPageNo'))
      : 1
  },
  reducers: {
    setListedIpoData: (state, action) => {
      state.ListedData = action.payload
    },
    setListedIpoType: (state, action) => {
      sessionStorage.setItem("listedIpoType", JSON.stringify(action.payload))
      state.Listedipotype = action.payload
    },
    setTypeListedFilter: (state, action) => {
      state.typeFilter = action.payload
    },
    startListedLoading: state => {
      state.Listedloading = true
    },
    stopListedLoading: state => {
      state.Listedloading = false
    },
    setListedError: (state, action) => {
      state.Listederror = action.payload
    },
    setListednext: (state, action) => {
      state.Listednext = action.payload
    },
    setListedprevious: (state, action) => {
      state.Listedprevious = action.payload
    },
    setListedPaginationLink: (state, action) => {
      state.listedPaginationLink = action.payload
    },
    setListedPageSize: (state, action) => {
      state.listedPageSize = action.payload
    },
    setListedPageNo: (state, action) => {
      state.listedPageNo = action.payload
      sessionStorage.setItem('listedPageNo', JSON.stringify(action.payload))
    }
  }
})

export const {
  setListedIpoData,
  setListedIpoType,
  setTypeListedFilter,
  startListedLoading,
  stopListedLoading,
  setListedError,
  clearListedError,
  setListednext,
  setListedprevious,
  setListedPaginationLink,
  setListedPageSize,
  setListedPageNo
} = ipoListedDataSlice.actions

export default ipoListedDataSlice.reducer
