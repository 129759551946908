import { createSlice } from '../../utils/npm'

const newsListSlice = createSlice({
  name: 'newsListSlice',
  initialState: {
    newsData: [],
    newsLoading: false,
    newsError: null,
  },
  reducers: {
    setNewsData: (state, action) => {
      state.newsData = action.payload
    },
    NewsStartLoading: state => {
      state.newsLoading = true
    },
    NewsStopLoading: state => {
      state.newsLoading = false
    },
    setNewsError: (state, action) => {
      state.newsError = action.payload 
    },
    clearNewsError: state => {
      state.newsError = null 
    }
  }
})

export const {
  setNewsData,
  NewsStartLoading,
  NewsStopLoading,
  setNewsError,
  clearNewsError,
} = newsListSlice.actions

export default newsListSlice.reducer
