import { createSlice } from '../../utils/npm'

const loginProfileSlice = createSlice({
  name: 'loginProfileSlice',
  initialState: {
    loginData: localStorage.getItem('login')
      ? JSON.parse(localStorage.getItem('login'))
      : {},
    profileData: localStorage.getItem('profile')
      ? JSON.parse(localStorage.getItem('profile'))
      : {}
  },
  reducers: {
    setLoginData: (state, action) => {
      state.loginData = action.payload
      localStorage.setItem('login', JSON.stringify(action.payload))
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload
      localStorage.setItem('profile', JSON.stringify(action.payload))
    },
    removeLoginData: (state) => {
      state.loginData = {};
      localStorage.removeItem('login')
    },
    removeProfileData: (state) => {
      state.profileData = {};
      localStorage.removeItem('profile')
    }
  }
})

export const {
  setLoginData,
  setProfileData,
  removeLoginData,
  removeProfileData
} = loginProfileSlice.actions

export default loginProfileSlice.reducer
