import { createSlice } from '../../utils/npm'

const ipoDetailSlice = createSlice({
  name: 'ipoDetailSlice',
  initialState: {
    ipoDetailData: {},
    ipoDetailLoading: false,
    ipoDetailError: ""
  },
  reducers: {
    setIpoDetail: (state, action) => {
      state.ipoDetailData = action.payload
    },
    startLoadingDetail: state => {
      state.ipoDetailLoading = true
    },
    stopLoadingDetail: state => {
      state.ipoDetailLoading = false
    },
    setErrorDetail: (state, action) => {
      state.ipoDetailError = action.payload
    },
    clearErrorDetail: state => {
      state.ipoDetailError = ""
    }
  }
})

export const {
  setIpoDetail,
  startLoadingDetail,
  stopLoadingDetail,
  setErrorDetail,
  clearErrorDetail
} = ipoDetailSlice.actions

export default ipoDetailSlice.reducer
